.App {
  background-color: #f0f8ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; 
  min-height: 100vh;
}

.App-header {
  padding: 16px;
  background-color: #282c34; /* Adjust the background color as needed */
  color: white;
  text-align: center;
}

.chart-container {
  text-align: left;
  width: 80%;
  margin-top: 50px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.textbox-container {
  width: 80%;
  margin-top: 50px;
  padding: 20px;
  background-color: #fff;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.date-picker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.date-picker-select {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
}

.date-picker-buttons {
  display: flex;
}


.date-picker-buttons button {
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #282c34;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}

